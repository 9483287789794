import {
  AppBar,
  IconButton,
  Toolbar,
  Divider,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { Row, Col, Drawer } from 'antd';
import styles from "./Navbar.module.css";
import clsx from "clsx";
import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import LocationModal from "../../../LocationModal/LocationModal";
import { useSelector, useDispatch } from "react-redux";
import ProfileDropdown from "../../ProfileDropdown";
import { logoutHandler } from "../../../../Redux/Auth/Actions";
import { chooseLocationHandler } from "../../../../Redux/Tarrif/Actions";
import logo from "../../../../assests/image/logo/SVG/Logo.svg";
import symbol from "../../../../assests/image/logo/SVG/symbol.svg";
import classNames from "classnames";
import ReactWhatsapp from 'react-whatsapp';
import { WhatsAppOutlined, PhoneFilled } from '@ant-design/icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: '110px'
  },
  navTitle: {
    padding: 5,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "black",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2.5%",
    justifyContent: "center",
  },
  hide: {
    display: "none",
  },
  menuButton: {
  },
  title: {
    flex: 1,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  loginBtn: {
    padding: "10px 20px",
    backgroundColor: "#eb952e",
    margin: theme.spacing(2),
    float: 'right'
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const currentLocation = useSelector(
    (state) => state.tarrifReducer.currentLocation
  );
  const userData = useSelector((state) => state.authReducer.userData);
  const isAuth = useSelector((state) => state.authReducer.isAuth);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dropDown, setDropDown] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onMouseOverHandler = () => {
    setDropDown(true);
  };

  const onMouseOffHandler = () => {
    setDropDown(false);
  };

  const locationHandler = (payload) => {
    setModalOpen(true);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleRouteChange = (to) => {
    history.push(to);
    closeDrawer();
  };

  const logoutClickHandler = () => {
    dispatch(logoutHandler());
    history.push("/");
    setDropDown(false);
  };

  React.useEffect(() => {
    dispatch(chooseLocationHandler("60489b1667b6df5e649d9671"));
  }, []);

  return (
    <Row>
      <Col span={24}>
        <div className={classNames(classes.root, 'nav-header')}>
          <LocationModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          ></LocationModal>
          <div className={styles.navParent}>
            <AppBar
              className={clsx(classes.appBar)}
            >
              <Toolbar className={styles.navbar}>
                <IconButton
                  edge="start"
                  className={classNames(classes.menuButton, 'nav-menu')}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Link to="/" className={'display-sd-hm'}>
                  <img
                    className={styles.homeLogo}
                    src={symbol}
                    alt="logo"
                  />
                </Link>
                <Link to="/" className={'display-sm-hd'}>
                  <img
                    className={styles.homeLogoMob}
                    src={symbol}
                    alt="logo-mob"
                  />
                </Link>
                <Link className={classNames(styles.tarrifLink, 'display-sd-hm')} to="/tariff">
                Tariff
                </Link>
                <Link className={classNames(styles.whatsNewLink, 'display-sd-hm')} to="/terms">
                  Terms And Conditions
                </Link>
                <Link className={classNames(styles.whatsNewLink, 'display-sd-hm')} to="/privacy-policy">
                  Privacy Policy
                </Link>
                <div className={classNames('rcb-ml-auto')}>
                  <span>
                    <PhoneFilled className="rcb-mr-5 rcb-ml-5" />
                    <a href="tel:+919449228603">+91-9449228603</a>
                  </span>
                  <span className="rcb-mr-5 rcb-ml-5">
                    <b>/</b>
                  </span>
                  <span>
                    <ReactWhatsapp className={classNames(styles.waBtn)} number="+919449228603" message="Hi, I am interested in renting your bike" >
                      <WhatsAppOutlined className="rcb-mr-5" />
                      Chat now
                    </ReactWhatsapp>
                  </span>
                </div>
                {/* {isAuth ? (
                  <div
                    onMouseLeave={onMouseOffHandler}
                    onMouseOver={onMouseOverHandler}
                    className={classNames(styles.profile, 'rcb-ml-auto')}
                  >
                    <img
                      src="https://d36g7qg6pk2cm7.cloudfront.net/assets/profile-f17aa1dfbd0cb562142f1dcb10bb7ad33e1ac8417ad29a1cdab7dfbfbbfe2f15.png"
                      alt="profile"
                    />
                    <span className={styles.currentUser}>
                      {userData.first_name || "User"}
                    </span>
                    <i className="fas fa-chevron-down"></i>
                    {dropDown && (
                      <ProfileDropdown
                        onMouseLeave={onMouseOffHandler}
                        onMouseOver={onMouseOverHandler}
                        logoutClickHandler={logoutClickHandler}
                      ></ProfileDropdown>
                    )}
                  </div>
                ) : (
                  <Link to="/authentication" className={classNames('rcb-ml-auto', 'display-sd-hm')} >
                    <Button className={classes.loginBtn}>Login</Button>
                  </Link>
                )} */}
                {/* <Link className={classNames('rcb-ml-auto', styles.searchLink)} to="/search">
                  <i class="fas fa-search"></i> Search
                </Link> */}
              </Toolbar>
            </AppBar>
          </div>
          <Drawer
            className={classes.drawer}
            placement="left"
            visible={open}
            zIndex={2000}
            onClose={closeDrawer}
          >
            <div className={classes.drawerHeader}></div>
            <Divider />
            {[
              {
                text: "Tariff",
                to: "/tariff",
              },
              {
                text: "Coorg Bike Routes",
              },
              {
                text: "Blog",
              },
              {
                text: "Terms And Conditions",
                to: '/terms'
              },
              {
                text: "About Us",
                // to: "/aboutUs",
              },
              {
                text: "Privacy Policy",
                to: "/privacy-policy"
              },
              {
                text: "FAQ",
              },
              {
                text: "Contact Us",
                // to: "/reachUs",
              },
              // {
              //   text: "Login",
              //   to: "/authentication",
              // },
            ].map((item, index) => (
              <>
                <ListItem
                  button
                  onClick={() => handleRouteChange(item.to)}
                  key={item.text}
                >
                  <ListItemText primary={item.text} className={styles.abc} />
                </ListItem>
                <Divider></Divider>
              </>
            ))}
          </Drawer>
        </div>
      </Col>
    </Row >
  );
}

export { Navbar };

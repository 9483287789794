import React ,{useEffect}from "react";
function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="rcb-ta-initial">
            <div className="WordSection1">
                <p className="MsoNormal" align="center" style={{ textAlign: "center" }}><b><u><span style={{
                    fontSize: '16px', color: 'black'
                }}>Privacy Policy</span></u></b></p>
                <p className="MsoNormal" style={{

                    background: 'white'
                }}><b><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>TopGear
                    Bike Rentals </span></b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>is a website (</span><span style={{
                        fontSize: '16px',
                        color: '#1890FF'
                    }}>www.topgearbikerentals.com</span><span
                        style={{
                            fontSize: '16px', color: 'black'
                        }}>) owned and
                        operated&nbsp;by<b> TOPGEAR AUTOLINKS</b>. A company registered and
                        incorporated under the Companies Act,2013, whose address is at&nbsp;<b>#</b></span><b><span
                            style={{
                                fontSize: '16px',
                                color: '#888888'
                            }}>&nbsp;</span></b><b><span
                                style={{
                                    fontSize: '16px', color: 'black'
                                }}>Plot no L5,
                                L6 Opp Shakthi Press, Industrial Area,&nbsp;Kohinoor road, Madikeri,
                                Kodagu-571201, Karnataka, India.</span></b></p>

                <p className="MsoNormal" style={{

                    background: 'white'
                }}><b><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>TopGear
                    Bike Rentals </span></b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>is strongly committed in protecting your privacy and has taken all
                        necessary and reasonable measures to protect your personal information and
                        handle the same in a safe and responsible manner in accordance with the terms
                        and conditions of this policy (“Privacy Policy”) set out herein below:</span></p>

                <p className="MsoNormal" style={{

                    background: 'white'
                }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>We view
                        protection of your privacy as a very important principle. We understand clearly
                        that you and your personal information is one of our most important assets. We
                        store and process your information, including any sensitive financial
                        information collected (as defined under the Information Technology Act, 2000),
                        if any, on computers that may be protected by physical as well as reasonable
                        technological security measures and procedures in accordance to Information
                        Technology Act 2000 and under reasonable security practices and procedures and
                        sensitive personal data of information under rules 2011 of IT Act 2000. Our current
                        privacy policy is available at www.topgearbikerentals.com/privacy-policy. If
                        you have any objections to your Information being transferred or used in this
                        way, we request you to avoid using this website.</span></p>

                <p className="MsoNormal" style={{

                    background: 'white'
                }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>We and our
                        affiliates will share / sell / transfer / license / convey some or all of your
                        personal information with other business entities should we (or our assets)
                        plan to merge with or are acquired by that business entity, or re-
                        organization, amalgamation, restructuring of business or for any other reason
                        whatsoever. Should such a transaction or situation occur, the other business
                        entity or the new combined entity will be required to follow the privacy policy
                        with respect to your personal information. Once you provide your information to
                        us, you provide information to us and our affiliates and we and our affiliates
                        may use such information to provide you various services with respect to your
                        transaction whether such transaction are conducted on www.topgearbikerentals.com
                        or with third party merchant's or third party merchant's website.</span></p>

                <p className="MsoNormal" style={{

                    background: 'white'
                }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>We may
                        collect your Personal Information as well as other information from third
                        parties like business partners; contractors; vendors.</span></p>

                <ul style={{ marginTop: '0in' }} type="disc">
                    <li className="MsoNormal" style={{ 'background': 'white' }}><b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>Use of Information</span></b></li>
                </ul>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>1.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>We will use
                        all your personal information to provide you a better rental experience through
                        www.topgearbikerentals.com</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>2.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To give you
                        proper information about best rental product for you</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>3.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To update
                        you on various new product and services</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>4.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To send you
                        statement or invoice</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>5.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To collect
                        and refund the payment to you</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>6.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To
                        Marketing/Advertising/Promotional or Business communications</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>7.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To notify
                        you on various security and administrative issues</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>8.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To respond
                        you on your queries and questions</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>9.</span> <span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>To send you
                        the information that may be of interest to you</span></p>

                <p className="MsoNormal" style={{
                    marginleft: '1.0in', background: 'white'
                }}><span style={{
                    fontSize: '16px', color: 'black'
                }}>10.</span><span style={{
                    fontSize: '16px',
                    color: 'black'
                }}>To address you if we got proven
                        point that you are violating the right of any third party or any agreement or
                        policy that govern your use of website services/product.</span></p>

                <ul style={{ marginTop: '0in' }} type="disc">
                    <li className="MsoNormal" style={{ 'background': 'white' }}><b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>Cookies</span></b></li>
                </ul>

                <p className="MsoNormal" style={{ marginleft: '.5in', lineheight: 'normal', background: 'white' }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>We may
                    store some information on your system such as cookies. Cookies are for record
                    keeping purpose and to ensure that you don’t need to provide your information
                    again and again. Cookies allow us to recognize who you are when you arrive at
                    our site by associating the identification numbers in the cookie with other
                    information you, as a site visitor, have provided us. We may place both
                    permanent and temporary cookies in your computer's hard drive. The cookies do
                    not contain any of your personally identifiable information.</span></p>

                <ul style={{ marginTop: '0in' }} type="disc">
                    <li className="MsoNormal" style={{ 'background': 'white' }}><b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>Third Party</span></b></li>
                </ul>

                <p className="MsoNormal" style={{ marginleft: '.5in', lineheight: 'normal', background: 'white' }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>We may have
                    some of third parties which may also ask for your information’s such as Payment
                    gateway, Marketing Partner, Business allies etc. we have all secure partners/
                    third parties after proper research about them we have tied up with them. The
                    Website does not have the ability to interfere and do not interfere with their
                    mechanism. The Website has no access to the information that you may enter for
                    the partner’s requirement. Your transaction and banking details or other
                    information as required for internet banking or other payment instruments is
                    held by our Payment Gateway partner. By creating a link to a payment gateway,
                    we do not endorse the payment gateway, nor are we liable for any failure of
                    products or services offered by such payment gateway. Such payment gateway may
                    have a privacy policy different from that of ours. All failures/ errors/
                    omissions of the payment gateway shall be solely on the payment gateway. You
                    hereby consent that you shall not sue the Website for any disputes that you may
                    have with the payment gateway for any wrong doing of the payment gateway.</span></p>

                <ul style={{ marginTop: '0in' }} type="disc">
                    <li className="MsoNormal" style={{ 'background': 'white' }}><b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>Grievances</span></b></li>
                </ul>

                <p className="MsoNormal" style={{ marginleft: '.5in', lineheight: 'normal', background: 'white' }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>If you have
                    any grievances against the Website privacy practices, or you apprehend that
                    your privacy is compromised at the Website, please mail us to bhavankochana@gmail.com&nbsp;
                    we assure you, we will pro-actively address your concerns.</span></p>

                <ul style={{ marginTop: '0in' }} type="disc">
                    <li className="MsoNormal" style={{ 'background': 'white' }}><b><span style={{
                        fontSize: '16px', color: 'black'
                    }}>Consent</span></b></li>
                </ul>

                <p className="MsoNormal" style={{ marginleft: '.5in', lineheight: 'normal', background: 'white' }}><span
                    style={{
                        fontSize: '16px', color: 'black'
                    }}>By
                    providing your information and using the website, you consent to the Privacy
                    Policy. Including but not limited to your consent for sharing your information
                    as per this privacy policy. If we decide to change our privacy policy, we can
                    change any policy at any point of time and will post those changes on this page
                    so that you are always aware of what information we collect, how we use it, and
                    under what circumstances we disclose it, you can see all these changes on our
                    website timely</span></p>

                <p className="MsoNormal">&nbsp;</p>

            </div>
        </div>
    );
}

export default PrivacyPolicy;

import React from "react";
import { Row, Col } from 'antd';
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Row>
      <Col span={24}>
        <div className={styles.Footer}>
          <div>
            <h4>TopGear bike rentals</h4>
            <div className={styles.Footer__highlight}></div>
            <div className={styles.Footer__flex}>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/white-mail-f7a800f328eab451231c558115ad0067bdd6c5ba04b7acd19f22ae4036e702d1.png"
                  alt="mail"
                ></img>
              </div>
              <div>topgearautolinks@gmail.com</div>
            </div>
            <div className={styles.Footer__flex}>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/white-phone-d7daea12563d63fb4bd1cbb2fa0a824d19501def56934aff125e23343912895e.png"
                  alt="mail"
                ></img>
              </div>
              <div>+919449228603</div>
            </div>
          </div>
          {/* <div>
            <h4>Company</h4>
            <div className={styles.Footer__highlight}></div>
            <div> 
              <p>Contact Us</p>
            </div>
          </div> */}
          <div>
            <h4>Terms & Policies</h4>
            <div className={styles.Footer__highlight}></div>
            <div>
              <p>
                <Link to="/privacy-policy">
                  Privacy Policy
                </Link>
              </p>
              <p>
                <Link to="/terms">
                  Terms & Conditions
                </Link>
              </p>
            </div>
          </div>
          <div>
            <h4>Links</h4>
            <div className={styles.Footer__highlight}></div>
            <div>
              <p>
                <Link to="/tariff">
                  Tariff
                </Link>
              </p>
            </div>
          </div>
          {/* <div>
            <h4>Follow Us</h4>
            <div className={styles.Footer__highlight}></div>
            <div className={styles.Footer__flex}>
              <div>
                <img
                  width="25px"
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/Insta-e068316ffc568b3c0162ef129542c4ec473fedb2317cc8132168a07a0616b475.png"
                  alt="social"
                ></img>
              </div>
              <div>
                <img
                  width="25px"
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/Facebook-ad24f92a1479ca4c5b308092f11f91d3ad2189d505ba2a737f376943a89b721b.png"
                  alt="social"
                ></img>
              </div>
              <div>
                <img
                  width="25px"
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/Linkedin-685bfa4464dc2263601740aad98014e02801299c7f2104be70458ac13d061fa1.png"
                  alt="social"
                ></img>
              </div>
              <div>
                <img
                  width="25px"
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/Youtube-35dbc9202b8e7b71d09c782934215b9f6a78a1b3b11d190b5ad275c004484295.png"
                  alt="social"
                ></img>
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.Footer__desc}>
          <h1>Bikes rentals in Coorg</h1>
          <p>
            Top Gear Bike Rentals is a trusted bike rental service located in Coorg since 2016.
            With the convenience of online/offline booking, customers can easily rent well-maintained
            bikes for exploring the beautiful surroundings of Coorg.Whether you are looking to cruise
            through scenic landscapes or navigate off-road trails, Top Gear Bike Rentals provides a wide
            range of bikes to suit different preferences and requirements.
          </p>
          <h2>Unforgettable Adventures Await: Explore Coorg with Top Gear Bike Rentals</h2>
          <p>With our focus on customer satisfaction and reliable service, Top Gear Bike Rentals ensures that customers
            receive bikes in excellent condition. This guarantees a safe and enjoyable ride throughout your exploration of Coorg.
            Whether you are a solo traveler or part of a group, Top Gear Bike Rentals has the right bike for you.</p>
          <h2>Book Your Bike Rental with Ease at <a href="https://topgearbikerentals.com/">topgearbikerentals.com</a></h2>
          <p>The website <a href="https://topgearbikerentals.com/">topgearbikerentals.com</a> provides an easy-to-use platform for booking your bike rental in advance.
            It offers detailed information about their services, bike options, and rental rates.
            This allows you to plan your trip and secure your bike rental with just a few clicks.
            With a user-friendly interface and informative content, the website ensures a smooth booking experience for customers.</p>
        </div>
        <div className={styles.Footer__end}>
          <h5>
            Made in Coorg
            {/* <img
              src="https://d36g7qg6pk2cm7.cloudfront.net/assets/icons/like-b8b570f724101252e5c39d8bd66246607fb226e93e3ba17b0516209b20832345.png"
              alt="love"
            ></img> */}
          </h5>
        </div>
      </Col>
    </Row>
  );
}

export default Footer;

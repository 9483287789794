import React, { useEffect } from "react";
import styles from "./Home.module.css";
import { SelectionContext } from "../../Context/SelectionContextProvider";
import { useSelector } from "react-redux";
import FleetCard from "./FleetCard";
import { useHistory } from "react-router";
import { Row, Col } from 'antd';
import moment from "moment";
import { DatePicker, Select } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import PickupForm from '../Form/PickupForm/PickupForm';
import Contact from "../Contact/Contact"

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}
const timeArray = [
  {
    "label": "06:00 AM",
    "value": "06:00 AM"
  },
  {
    "label": "06:30 AM",
    "value": "06:30 AM"
  },
  {
    "label": "07:00 AM",
    "value": "07:00 AM"
  },
  {
    "label": "07:30 AM",
    "value": "07:30 AM"
  },
  {
    "label": "08:00 AM",
    "value": "08:00 AM"
  },
  {
    "label": "08:30 AM",
    "value": "08:30 AM"
  },
  {
    "label": "09:00 AM",
    "value": "09:00 AM"
  },
  {
    "label": "09:30 AM",
    "value": "09:30 AM"
  },
  {
    "label": "10:00 AM",
    "value": "10:00 AM"
  },
  {
    "label": "10:30 AM",
    "value": "10:30 AM"
  },
  {
    "label": "11:00 AM",
    "value": "11:00 AM"
  },
  {
    "label": "11:30 AM",
    "value": "11:30 AM"
  },
  {
    "label": "12:00 PM",
    "value": "12:00 PM"
  },
  {
    "label": "12:30 PM",
    "value": "12:30 PM"
  },
  {
    "label": "01:00 PM",
    "value": "01:00 PM"
  },
  {
    "label": "01:30 PM",
    "value": "01:30 PM"
  },
  {
    "label": "02:00 PM",
    "value": "02:00 PM"
  },
  {
    "label": "02:30 PM",
    "value": "02:30 PM"
  },
  {
    "label": "03:00 PM",
    "value": "03:00 PM"
  },
  {
    "label": "03:30 PM",
    "value": "03:30 PM"
  },
  {
    "label": "04:00 PM",
    "value": "04:00 PM"
  },
  {
    "label": "04:30 PM",
    "value": "04:30 PM"
  },
  {
    "label": "05:00 PM",
    "value": "05:00 PM"
  },
  {
    "label": "05:30 PM",
    "value": "05:30 PM"
  },
  {
    "label": "06:00 PM",
    "value": "06:00 PM"
  },
  {
    "label": "06:30 PM",
    "value": "06:30 PM"
  },
  {
    "label": "07:00 PM",
    "value": "07:00 PM"
  },
  {
    "label": "07:30 PM",
    "value": "07:30 PM"
  },
  {
    "label": "08:00 PM",
    "value": "08:00 PM"
  },
  {
    "label": "08:30 PM",
    "value": "08:30 PM"
  },
  {
    "label": "09:00 PM",
    "value": "09:00 PM"
  }
]
function Home() {
  // const allBikes = useSelector((state) => state.tarrifReducer.allBikes);
  const allBikes = useSelector((state) => {
    const bikes = state?.tarrifReducer?.allBikes;
    const result = bikes.filter((bike, index, self) =>
      index === self.findIndex((t) => (t.bike_name === bike.bike_name))
    );
    return result;
  });
  const history = useHistory();

  const exploreHandler = () => {
    history.push("/search");
  };

  const { onSubmitHandler, onChangeHandler, info } = React.useContext(
    SelectionContext
  );
  const { pickupDate, pickupTime, dropDate, dropTime } = info;
  const { Option } = Select;
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  }
  const dateFormat = 'DD-MM-YYYY';
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Row>
      <Col span={24}>
        <div className={styles.Home}> 
          <div className={styles.Home__header}> 
            <div className={styles.Search}>
              <h1>Find your ride</h1>
              <PickupForm />
            </div>
          </div>
          <div className={styles.Home__services}>
            {/* <div className={styles.Home__services__main}>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/redesign/ads_one-db70a8af7a98baaee8e83c4201f1f3187f6f9a86adadfda982e3683304cd6f0d.png"
                  alt="sanitizer"
                ></img>
              </div>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/redesign/ads_two-74137d80a5e444d475385d2be95581028dbe9dd0c246bb53f7b56fea44723f13.png"
                  alt="delivery"
                ></img>
              </div>
            </div> */}
            <div className={styles.Home__services__foot}>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/redesign/feature_tag_insurance-84ee18a118bee4cfc4f774e0dbdb5d24431af458eb583d5d1c5822801e388764.png"
                  alt="insurance"
                ></img>
                <span>Vehicle Insurance</span>
              </div>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/redesign/feature_tag_assistance-ec66ebeeb0ebb01468fc1729165040e172820de83d5037cf47a73f4e19177d68.png"
                  alt="assistance"
                ></img>
                <span>Roadside Assistance</span>
              </div>
              <div>
                <img
                  src="https://d36g7qg6pk2cm7.cloudfront.net/assets/redesign/feature_tag_maintainence-4ac25e7eb9ad6f8b78ec52640a7310d8083a21823e1fb54ca9872076c2bc5f34.png"
                  alt="maintenance"
                ></img>
                <span>Bike Maintenance</span>
              </div>
            </div>
          </div>
          <div className={styles.Home__fleet}>
            <div className={styles.Home__fleet__head}>
              <h1>Our Fleet</h1>
              <div className={styles.Home__bar}></div>
            </div>
            <div className={styles.Home_fleet_cards}>
              {allBikes
                ?.filter((_, index) => index < 3)
                .map((item) => (
                  <FleetCard key={item._id} {...item}></FleetCard>
                ))}
            </div>
          </div>
        </div >
      </Col >
      <Col span={24}>
        <Contact />
      </Col>
    </Row >
  );
}

export default Home;

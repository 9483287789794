import React from "react";
import { Row, Col } from 'antd';
import styles from "./Contact.module.css";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDw5YJxCJnA65Mw01Kbtq5-h43kbkgsb4A&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={18} defaultCenter={{ lat: 12.4231372, lng: 75.7347265 }}>
    {props.isMarkerShown && (
      <Marker position={{ lat: 12.4231372, lng: 75.7347265 }} />
    )}
  </GoogleMap>
));

function Contact() {
  return (
    <Row className={styles.contactTopPad}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <div className={styles.Home__fleet__head}>
              <h1>Contact Us</h1>
              <div className={styles.Home__bar}></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={24} sm={24} className={styles.maps} >
            <MyMapComponent isMarkerShown />
          </Col>
          <Col lg={12} md={24} sm={24}>
            <div className={styles.sec2addr}>
              <p># Plot no L5, L6 Opp Shakthi Press, Industrial Area, Kohinoor road, Madikeri, Kodagu-571201, Karnataka, India</p> 
              <p><span>Phone :</span><a href="tel:+918884447142">+91 8884447142 / 52 / 62</a></p> 
              <p><span>Email :</span><a href="mail:topgearautolinks@gmail.com"> topgearautolinks@gmail.com</a></p>
            </div>
          </Col>
        </Row>
        {/* <div className="container">
          <div className="innerwrap">

            <section className="section1 clearfix">
              <div className="textcenter">
                <span className="shtext">Contact Us</span>
                <span className="seperator"></span>
                <h1>Drop Us a Mail</h1>
              </div>
            </section>

            <section className="section2 clearfix">
              <div className="col2 column1 first">
                <MyMapComponent isMarkerShown />
              </div>
              <div className="col2 column2 last">
                <div className="sec2innercont">
                  <div className="sec2addr">
                    <p>45 BC, a Latin professor at Hampden-Sydney College in Virginia</p>
                    <p><span className="collig">Phone :</span> +91 976885083</p>
                    <p><span className="collig">Email :</span> vivek.mengu016@gmail.com</p>
                    <p><span className="collig">Fax :</span> +91 9768850839</p>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div> */}
      </Col>
    </Row>
  );
}

export default Contact;

import React from "react";
import styles from "./PickupForm.module.css";
import moment from "moment";
import { Form, DatePicker, Select } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router";

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days').endOf('day');
}
const timeArray = [
  {
    "label": "06:00 AM",
    "value": "06:00 AM"
  },
  {
    "label": "06:30 AM",
    "value": "06:30 AM"
  },
  {
    "label": "07:00 AM",
    "value": "07:00 AM"
  },
  {
    "label": "07:30 AM",
    "value": "07:30 AM"
  },
  {
    "label": "08:00 AM",
    "value": "08:00 AM"
  },
  {
    "label": "08:30 AM",
    "value": "08:30 AM"
  },
  {
    "label": "09:00 AM",
    "value": "09:00 AM"
  },
  {
    "label": "09:30 AM",
    "value": "09:30 AM"
  },
  {
    "label": "10:00 AM",
    "value": "10:00 AM"
  },
  {
    "label": "10:30 AM",
    "value": "10:30 AM"
  },
  {
    "label": "11:00 AM",
    "value": "11:00 AM"
  },
  {
    "label": "11:30 AM",
    "value": "11:30 AM"
  },
  {
    "label": "12:00 PM",
    "value": "12:00 PM"
  },
  {
    "label": "12:30 PM",
    "value": "12:30 PM"
  },
  {
    "label": "01:00 PM",
    "value": "01:00 PM"
  },
  {
    "label": "01:30 PM",
    "value": "01:30 PM"
  },
  {
    "label": "02:00 PM",
    "value": "02:00 PM"
  },
  {
    "label": "02:30 PM",
    "value": "02:30 PM"
  },
  {
    "label": "03:00 PM",
    "value": "03:00 PM"
  },
  {
    "label": "03:30 PM",
    "value": "03:30 PM"
  },
  {
    "label": "04:00 PM",
    "value": "04:00 PM"
  },
  {
    "label": "04:30 PM",
    "value": "04:30 PM"
  },
  {
    "label": "05:00 PM",
    "value": "05:00 PM"
  },
  {
    "label": "05:30 PM",
    "value": "05:30 PM"
  },
  {
    "label": "06:00 PM",
    "value": "06:00 PM"
  },
  {
    "label": "06:30 PM",
    "value": "06:30 PM"
  },
  {
    "label": "07:00 PM",
    "value": "07:00 PM"
  },
  {
    "label": "07:30 PM",
    "value": "07:30 PM"
  },
  {
    "label": "08:00 PM",
    "value": "08:00 PM"
  },
  {
    "label": "08:30 PM",
    "value": "08:30 PM"
  },
  {
    "label": "09:00 PM",
    "value": "09:00 PM"
  }
]
function PickupForm() {

  const history = useHistory();

  const exploreHandler = () => {
    history.push("/search");
  };

  const { Option } = Select;

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  }

  const dateFormat = 'DD-MM-YYYY';

  const onFinish = (values) => {
    history.push("/tariff");
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <label>Pickup</label>
      <div className={styles.Search__element}>
        <div>
          <Form.Item
            name="pickupDate"
            rules={[{ required: true, message: 'Please select Date!' }]}
          >
            <DatePicker
              onChange={onChange}
              style={{ width: '90%' }}
              placeholder={'Date'}
              disabledDate={disabledDate}
              size={'large'}
              format={dateFormat} />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="pickupTime"
            rules={[{ required: true, message: 'Please select Time!' }]}
          >
            <Select onChange={handleChange}
              style={{ width: '90%' }}
              name="pickupTime" placeholder={'Time'}
              suffixIcon={<ClockCircleOutlined />}
              size={'large'}>
              {timeArray.map(time => <Option value={time.value}>{time.label}</Option>)}
            </Select>
          </Form.Item>
        </div>
      </div>
      <label>DropOff</label>
      <div className={styles.Search__element}>
        <div>
          <Form.Item
            name="dropDate"
            rules={[{ required: true, message: 'Please select Date!' }]}
          >
            <DatePicker
              onChange={onChange}
              style={{ width: '90%' }}
              name="dropDate"
              placeholder={'Date'}
              disabledDate={disabledDate}
              size={'large'}
              format={dateFormat} />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="dropTime"
            rules={[{ required: true, message: 'Please select Time!' }]}
          >
            <Select
              onChange={handleChange}
              style={{ width: '90%' }}
              name="dropTime" placeholder={'Time'}
              suffixIcon={<ClockCircleOutlined />}
              size={'large'} >
              {timeArray.map(time => <Option value={time.value}>{time.label}</Option>)}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className={styles.Search__element}>
        <button type="submit">Search</button>
      </div>
    </Form>
  );
}

export default PickupForm;

import React ,{useEffect}from "react";
function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="rcb-ta-initial">
            <div className="WordSection1">

                <p className="MsoNormal"  ><b><u><span
                    style={{ fontSize: '16px', color: 'black' }}>Terms &amp;
                    Conditions</span></u></b></p>

                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Payment
                        process</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Rental
                            charges has to be paid online/offline for the duration chosen while
                            booking the bike</span></li>
                    </ul>
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Delay</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>A fee
                            of 200 of the rental charges per hour will be charged for vehicle being
                            returned after the grace period of 30 mins.</span></li>
                    </ul>
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Deposit</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            customer has to deposit original driving license at the time of pickup and it
                            will be returned at the time of returning the vehicle.</span></li>
                    </ul>
                    <li className="MsoNormal" style={{ background: '#FAFAFA' }}  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Prohibited Area</span></b></li>
                    <ul type="circle" style={{ background: '#FAFAFA' }}>
                        <li className="MsoNormal" style={{ background: '#FAFAFA' }}  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Our bikes are not allowed to ride to&nbsp;<b>Mandalpatti</b>&nbsp;due
                            to unhealthy road profile. Violation of the condition will attract penalty
                            which has to be paid while drop off. Also, road side assistance will not be
                            provided in case of vehicle breakdown.</span></li>
                    </ul>
                </ul>

                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Documents</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            user must have a valid driving license issued by the Government.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            user must take a clear picture of his/her driving license and upload it
                            on our website/App.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            user must carry his/her original documents at the time of the pickup
                            which were uploaded on the website/App for verification.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Documents
                            issued by the government of India such as Aadhar Card, Driver’s license,
                            Passport, etc. will be considered as valid ID and address proof. (Voter
                            ID and Pan Card can be provided as an additional document if required.)</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>If a
                            user is unable to verify his/her identity then&nbsp;<b>TOPGEAR BIKE
                                RENTALS </b>reserve the right to deny the service and cancel the booking
                            with the 37.5% cancellation charges as per the policy.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px' }}>For
                            international users, we would require a valid driving license or an
                            international driving permit along with, a copy of the passport and a
                            valid visa.</span></li>
                        <li className="MsoNormal"  ><b><span
                            style={{ fontSize: '16px', color: 'black' }}>*Note:
                            - Learner license is not applicable for renting a vehicle with us.</span></b></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Fuel
                        Policy</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Fuel
                            is client’s responsibility. We will be giving you sufficient fuel to
                            reach the nearest fuel pump.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Cancellation
                        policy as below:</span></b></li>
                </ul>

                <ul type="disc"> <b><p
                    style={{ fontSize: '16px', color: 'black', marginLeft: '30px' }}>Booking related</p></b>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>No
                            show - 100% deduction and only security deposit will be refunded</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Between
                            0 - 6 hrs of the pickup time - 75% rental charges will be withheld and
                            security deposit will be refunded.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Between
                            6-24 hrs of the pickup time: 50% rental charges will be withheld.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Between
                            24-72 hrs of the pickup time: 25% rental charges will be withheld.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>72
                            hrs or more prior to the pickup time: 10% rental charges will be
                            withheld.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Cancellation due to document issue:</span></b></li>
                </ul>
                < ul type="disc" >
                    < p className="MsoNormal" ><b><span style={{ fontSize: '16px', color: 'black', marginLeft: '30px' }}>37.5% of the rental amount will be deducted, if:</span></b></p>

                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Customer
                            is not willing to submit the document as per the terms and conditions.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Customer
                            is not able to verify his/her identity.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Customer
                            documents are not valid.</span></li>
                    </ul>
                </ul >
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Extra
                        charges such as the following will be applicable -</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>If a
                            customer chooses an extra helmet on the spot, it will be billed
                            additionally on the spot.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Excess
                            Km - The excess km charges will be calculated at the time of drop off and
                            the customer will be required to pay the same at the parking location.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Any
                            damages will be inspected at the spot and the charges will be collected
                            on the spot as per the damage terms mentioned.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Process to Rent Helmet</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Helmets
                            need to be booked in advance.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Helmets
                            will be charged @ 50/day.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>If the
                            helmet is damaged or lost, a charge of Rs. 900 will be levied since these are
                            branded helmets which will be provided to you.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Only one
                            helmet will be provided per person, any extras will be charged at Rs. 50 per 24
                            hours.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Cap on riding km</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            agrees to adhere to the km cap limit of <b>TOPGEAR BIKE RENTALS</b>. The Bikes
                            have a km limit of 240km per day basis and the scooters have a km limit of 120
                            km per day basis.</span></li>
                    </ul>
                </ul>

                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Damage
                        policy</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            Lessee agrees to pay for any damage to, loss of, or any theft
                            (disappearance) of parts of bike, regardless of cause or fault. Item
                            damaged beyond repair will be paid for at its Market Price.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            representative shall check the bike and its parts in order to ascertain
                            any damage to items. Damage shall be defined as follows:</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Any
                            damage which existed prior to the handover of the Motorbike and was
                            agreed between the lessee and lessor will not be chargeable to the
                            Lessee.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Tear
                            in seat cover will result in charge towards replacement of seat cover.
                            Opening up a stitched joint will not be chargeable.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Any
                            variation showing damages, if ascertained as not caused by normal wear
                            and tear, would be charged and would have to be borne by the Lessee</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>In
                            case of an accident or collision, the rider members are accountable to
                            pay a standard amount towards the accident insurance not exceeding Rs.
                            15,000(which may vary depending on the severity of the damage). The
                            members are bound to pay for the repairs incurred due to damages caused
                            by the collision/accident.</span></li>
                        <li className="MsoNormal"  ><b><span
                            style={{ fontSize: '16px', color: 'black' }}>Any
                            charges exceeding Rs. 15,000 will be claimed from the insurance.</span></b></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            above point is not valid for bikes exceeding 200cc. In such cases the
                            actual repair amount must be borne by the rides/user. The insurance will
                            only be claimed if the loss is exceeding 25% of the bike value.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>In
                            the event of a collision, either due to the fault of the rider himself or
                            due to any other unavoidable instance, the costs involved in towing or
                            confiscation of TG bikes are to be borne by the members alone</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Inactive
                            hours during the repair period shall be charged to the rider member at
                            50% of the per day rental amount</span></li>
                    </ul>
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Handover
                        process</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Lessee
                            has to inform us 2 hrs in advance before the end of the contract Period.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Pick-up
                            date and time will be mutually decided by lessee and us. The drop –off
                            location will be the same as the pick-up location.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Lessee
                            has to be present at the agreed date and time.</span></li>
                    </ul>
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Right
                        to refuse the service</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            company holds the right of refusal to any client not deemed fit to be
                            served by the company or its authorized staff</span></li>
                    </ul>
                </ul>

                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Over speeding</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The bikes
                            have to be ridden within permissible limits. The speed limit for each vehicle
                            is different which will be specified at the time of booking. You have to be
                            under the speed limit specified by the company or the speed limit specified by
                            the governing authority, whichever is lesser.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Use of
                        Vehicle</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>These
                            motorcycles we rent are large, heavyweight, very powerful and not
                            appropriate for beginner or inexperienced riders.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            lessee should have a valid driving license, be at least 18 years old and
                            must always wear a helmet while riding.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Must
                            have appropriate riding gear, i.e... Boots or closed toe shoes, pants
                            &amp; eyewear. No flip-flops, sandals or shorts.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Only
                            the lessee is permitted to drive the vehicle</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Lessee
                            is responsible for his insurance; Lessor will not be responsible for any
                            accidental expenses of Rider.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            lessee shall use the bike entirely at his/her own risk and agrees that
                            lessor will not accept any responsibility or be held accountable for any
                            loss, injury or death as a result of, or leading from the hire of any of
                            the vehicles.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            bike shall be returned back to lessor in the same condition in which it
                            was handed over to lessee.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>In
                            case the vehicle returned is found excessively dirty/muddy, the lessee
                            will have to bear the charge of washing not exceeding 200 Rupees.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Rental
                            package does not include Fuel, Toll, road side assistance and Taxes</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Lessee
                            is responsible for any traffic violations incurred due to your use of a
                            rented vehicle. Lessor is not liable for any costs from any such
                            violation.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>You
                            must report such violations to a Lessor’s Representative as soon as
                            possible.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Towing
                            service will be borne by the customer in case of unlikely event of tire
                            puncture or collision or breakdown due to improper usage. In the event of
                            mechanical or technical failure, we will take care in towing the bike at
                            our own cost</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>All
                            our bikes have GPS tracking devices; we recommend that you follow speed
                            guidelines – our team gets automated updates on the bikes that exceed
                            speed limits.</span></li>
                    </ul>
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Prohibited
                        Uses</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            use of a Lessor’s vehicle under the following conditions is prohibited:</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Our
                            motorcycles cannot be used for rallies and rally surcharges or any format
                            of professional or amateur competitions and media usage.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>By
                            any person who is under the influence of (i) alcohol or (ii) any drug or
                            medication under the effects of which the operation of a vehicle is
                            prohibited or not recommended.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>In
                            carrying out of any crime or any other illegal activity.</span></li>
                    </ul>
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Delivery
                        process</span></b></li>
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Lessee
                            has to be present at the agreed date and time to pick up the bike.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Delivered
                            vehicle cannot be rejected after handover. Once accepted by Lessee or
                            his/her representative at the time of pickup, vehicle is not to be
                            returned before agreed contract period. Lessee should do quality test of
                            bike before he accepts bike from Lessor.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>Though
                            we do quality checks at our end before delivery, lessee is expected to
                            see if there are any damages and report the same to representative of
                            lessor and photos shall be captured of the same.</span></li>
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>One
                            signed copy of the contract is to be kept by each party.</span></li>
                    </ul>
                </ul>

                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Process</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  >  <p className="MsoListParagraphCxSpMiddle" ><b><span style={{ fontSize: '16px', color: 'black' }}>TOPGEAR BIKE RENTALS </span></b><span style={{ fontSize: '16px', color: 'black' }}>may send booking confirmation,
                            itinerary information, cancellation, payment confirmation, refund status,
                            schedule change or any such other information relevant for the transaction, via
                            SMS or by voice call on the contact number given by the User at the time of
                            booking;&nbsp;<b>TOPGEAR BIKE RENTALS </b>may also contact the User by voice
                            call, SMS or email in case the User couldn't or hasn't concluded the booking,
                            for any reason what so ever or to know the preference of the User for
                            concluding the booking and also to help the User for the booking. The User
                            hereby unconditionally consents that such communications via SMS and/ or voice
                            call by&nbsp;<b>TOPGEAR BIKE RENTALS</b></span></p>
                            <p className="MsoListParagraphCxSpMiddle" ><span style={{ fontSize: '16px', color: 'black' }}>(a) upon the request and authorization of the User, </span></p>

                            <p className="MsoListParagraphCxSpMiddle" ><span style={{ fontSize: '16px', color: 'black' }}>(b) 'transactional' and not an 'unsolicited commercial
                                communication' as per the guidelines of Telecom Regulation Authority of India
                                (TRAI)</span></p>

                            <p className=" MsoListParagraphCxSpLast"  ><span style={{ fontSize: '16px', color: 'black' }}>(c) in compliance with the relevant guidelines of TRAI or such
                                other authority in India and abroad. </span></p>

                            <p className="MsoNormal" ><span
                                style={{ fontSize: '16px', color: 'black' }}>The User
                                will indemnify&nbsp;<b>TOPGEAR BIKE RENTALS </b>against all types of losses and
                                damages incurred by&nbsp;<b>TOPGEAR BIKE RENTALS </b>due to any action taken by
                                TRAI, Access Providers (as per TRAI regulations) or any other authority due to
                                any erroneous compliant made by the User against <b>TOPGEAR BIKE RENTALS </b>with
                                respect to the intimations mentioned above or on account of any wrong number or
                                email id provided by the User for any reason whatsoever.</span></p>
                        </li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Maintenance</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            customer is liable for checking engine oils during a trip and maintaining the
                            vehicle while on road. Any mechanical failures should be reported immediately.
                            Customers may be held responsible in case of a mechanical failure resulting due
                            to negligence of the normal maintenance while on the trip.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Use of
                        Information</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  ><span
                            style={{ fontSize: '16px', color: 'black' }}>The user’s
                            first name and booking details may be used for showing booking popup to other
                            users on our homepage. No other personally identifiable information will be
                            shown. Please read the Privacy Policy for more details regarding use of
                            information.</span></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Assumption of risk</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li className="MsoNormal"  ><p className="MsoNormal" ><b><span
                            style={{ fontSize: '16px', color: 'black' }}>FOR
                            OPERATORS AND PASSENGERS OF VEHICLES (INCLUDING PROTOTYPE VEHICLES) OWNED OR
                            CONTROLLED BY Topgear Autolinks. OPERATING UNDER THE TOPGEAR BIKE RENTALS brand
                            name.</span></b></p></li>
                        <li>
                            <p className="MsoNormal" ><span
                                style={{ fontSize: '16px', color: 'black' }}>The rider
                                has been given the opportunity to operate ride upon or otherwise use one or
                                more motorcycles or other vehicles owned or controlled by&nbsp;<b>Topgear
                                    Autolinks</b>&nbsp;OPERATING UNDER THE&nbsp;<b>TOPGEAR BIKE RENTALS </b>BRAND.;
                                (collectively, the “Company”; each such company owned or controlled vehicle is
                                referred to herein as a “C.O.C.V.”),</span></p>
                        </li>
                        <li>
                            <p className="MsoNormal" ><span
                                style={{ fontSize: '16px', color: 'black' }}>The rider
                                fully understands and acknowledges that operating or riding on a vehicle is an
                                activity that has its own unique, inherent risks, and that serious injury or
                                death could result from operating or riding on a C.O.C.V. through no fault of
                                his own. The rider (lessee) is voluntarily choosing to operate and/or ride upon
                                C.O.C.V.s.</span></p>
                        </li>
                        <li>      <p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>By
                            accepting the terms and conditions, THE RIDER EXPRESSLY AGREE TO ASSUME THE
                            ENTIRE RISK OF ANY ACCIDENTS, PROPERTY DAMAGE OR PERSONAL INJURY, INCLUDING
                            PERMANENT DISABILITY, PARALYSIS AND DEATH, THAT HE/SHE MIGHT SUFFER AS A RESULT
                            OF HIM/HER OPERATING OR RIDING ON A C.O.C.V.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>Without
                            limiting the foregoing, THE RIDER ASSUME ALL RISKS ARISING FROM THE FOLLOWING:
                            (i) the condition or safety of the C.O.C.V.s, any other vehicles or equipment,
                            or any roadways, premises or property; (ii) the repair or maintenance, or lack
                            thereof, of the C.O.C.V.s or any other vehicles or equipment; (iii) the use of,
                            or failure to use, any safety devices or safeguards; (iv) the conditions, qualifications,
                            instructions, rules or procedures under which any C.O.C.V is used; (v) the
                            violation by me or by other operators or passengers of any C.O.C.V. User
                            Eligibility or Program Rules; (vi) any defective or unreasonably dangerous
                            products, components or C.O.C.V.s; (vii) the weather conditions during
                            operation of any C.O.C.V.s and (viii) single and/or multi-vehicle accidents.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            (lesee) acknowledges that some or all of the C.O.C.V.s that I am allowed to
                            operate, ride upon or otherwise use may be prototypes or pre-production models.
                            It’s understood that prototype and pre-production C.O.C.V.s are not
                            manufactured for general consumer use and are not fully tested. Also,
                            understood point is that there in increased risk involved in operating or riding
                            upon prototype or pre-production C.O.C.V.s, and THE RIDER (LESSEE) AGREE TO
                            ASSUME THAT RISK.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            at the time of booking and riding declares not to be under the influence of
                            alcohol, drugs, other illegal substances, or any medications that may impair
                            his/her judgment or his/her ability to operate or ride on a C.O.C.V. The rider
                            agrees that he/she will not operate or ride on any C.O.C.V. while under the
                            influence of alcohol, drugs, other illegal substances or any such medications.</span></p></li>
                        <li> <p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            agrees that he/she will not operate or ride upon any C.O.C.V. unless he/she
                            have first examined and satisfied himself that the C.O.C.V. is in good
                            operating condition, and that he/she fully understands how to operate and ride
                            on that C.O.C.V.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            agrees to operate or ride on each C.O.C.V. safely, defensively and within the
                            limits of the law and own abilities. The rider (lessee) agrees to comply with
                            all C.O.C.V. user eligibility and program rules.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            also agrees to allow the company and their respective dealers to contact
                            him/her at the address, telephone number and e-mail address provided below and
                            gather additional information about the C.O.C.V.s and/or additional information
                            about other goods and services in which one may be interested.</span></p></li>
                    </ul>
                </ul>
                <ul type="disc">
                    <li className="MsoNormal"  ><b><span
                        style={{ fontSize: '16px', color: 'black' }}>Waiver and release of liability</span></b></li>
                </ul>
                <ul type="disc">
                    <ul type="circle">
                        <li>
                            <p className="MsoNormal" ><span
                                style={{ fontSize: '16px', color: 'black' }}>FOR
                                OPERATORS AND PASSENGERS OF VEHICLES (INCLUDING PROTOTYPE VEHICLES) OWNED OR
                                CONTROLLED BY&nbsp;<b>Topgear Autolinks</b>, OPERATING UNDER THE&nbsp;<b>TOPGEAR
                                    BIKE RENTALS </b>BRAND.</span></p>
                        </li>
                        <li>
                            <p className="MsoNormal" ><span
                                style={{ fontSize: '16px', color: 'black' }}>The
                                rider(Lessee) on behalf of all heirs, personal representatives, administrators,
                                executors, successors and assigns (collectively, “Heirs and Assigns”), for and
                                in considerations of the permission granted to him to operate, ride upon or
                                otherwise use one or more motorcycles or other vehicles owned or controlled
                                by&nbsp;<b>Topgear Autolinks</b>., operating under the&nbsp;<b>TOPGEAR BIKE
                                    RENTALS </b>brand (collectively, the “Company”; each such company owned or
                                controlled vehicle is referred to herein as a “C.O.C.V.”), and for other
                                valuable considerations, the receipt and adequacy of which are hereby
                                acknowledged, RELEASE, WAIVE AND FOREVER DISCHARGE the Company and each of
                                their respective affiliated companies, subsidiaries, officers, directors,
                                employees, dealers, distributors and agents (collective, the “Released
                                Parties”), from ANY AND ALL CLAIMS, DEMANDS, RIGHTS AND CAUSES OF ACTION
                                (Collectively, “Claims”) OF ANY KIND WHATSOEVER THAT THE RIDER OR ANY OF HIS
                                HEIRS AND ASSIGNS NOW HAVE OR LATER MAY HAVE AGAINST ANY RELEASED PARTY
                                RESULTING FROM, CONNECTED WITH OR ARISING OUT OF MY OPERATION OF OR RIDING ON
                                ANY C.O.C.V., REGARDLESS OF WHETHER SUCH CLAIMS RELATE TO THE DESIGN,
                                MANUFACTURE, REPAIR, OPERATION OR MAINTENANCE OF ANY C.O.C.V. OR THE
                                CONDITIONS, RULES, QUALIFICATIONS, INSTRUCTIONS OR PROCEDURES UNDER WHICH ANY
                                C.O.C.V. IS USED, OR ANY OTHER CAUSE OR MATTER.</span></p>
                        </li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            rider(Lessee) acknowledges and understands that this Release EXTENDS TO AND
                            RELEASES AND DISCHARGES ANY AND ALL CLAIMS he/she or any Heirs and Assigns have
                            or later may have against the Released Parties resulting from or arising out of
                            my operation or of riding on C.O.C.V.s including without limitation all such
                            Claims resulting from the NEGLIGENCE of any Released Party, or arising from
                            STRICT PRODUCT LIABILITY, or resulting from any BREACH OF ANY EXPRESS OR
                            IMPLIED WARRANTY by any Released Party, and regardless of whether such Claims now
                            exist or hereafter arise or are known or unknown, contingent or absolute,
                            liquidated or unliquidated or foreseen or unforeseen, or arise by operation of
                            law or otherwise.</span></p></li>
                        <li> <p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            rider(Lessee) acknowledges and understands that by agreeing to this Release he/she
                            and his/her Heirs and Assigns AGREE NOT TO USE any or all of the Released
                            Parties for any injury, death or damage to myself, my property, any other
                            person or such other person’s property resulting from or arising out of my
                            operation of or riding on any C.O.C.V.s</span></p></li>
                        <li> <p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The
                            rider(Lessee) acknowledge that he/she has been advised of and agree to waive on
                            his/her behalf and on behalf of his/her Heirs and Assigns, and fully understand
                            the effect of such waiver, all benefits flowing from any state statute that
                            would otherwise limit the scope of this release, which provides: “A general
                            release does not extend to claims which[a person] does not know or suspect to
                            exist in his or her favour at the time of executing the release, which if known
                            by him or her must have materially affected his or her settlement with the
                            released parties”.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>The rider
                            confirms that this release is intended to be as broad and inclusive as is
                            permitted by law. The extent and the scope of this Release is unenforceable in
                            any jurisdiction, said scope will, as to such jurisdiction only, be
                            automatically limited to the extent necessary to make this Release enforceable
                            in such jurisdiction, without invalidating any other person of this Release.
                            The rider hereby waives any right to bargain for different release or waiver of
                            liability terms.</span></p></li>
                        <li><p className="MsoNormal" ><span
                            style={{ fontSize: '16px', color: 'black' }}>BY AGREEING
                            TO THIS RELEASE, THE RIDER CERTIFIES THAT HE/SHE HAS READ THIS RELEASE AND
                            FULLY UNDERSTAND IT AND NOT RELYING ON ANY STATEMENTS OR REPRESNTATIONS OF ANY
                            OF THE RELEASED PARTIES, AND HAVE BEEN GIVEN THE OPPORTUNITY AND SUFFICIENT
                            TIME TO READ AND ASK QUESTIONS REGARDING THIS RELEASE.</span></p></li>
                    </ul>
                </ul>
            </div >
        </div >
    );
}

export default Terms;
